import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationDepartment } from "../../../models/organisation/application-department";
import DepartmentService from "../../../services/organisation/department-service";
import { ApplicationError } from "../../../models/errors/application-error";
import { EditApplicationDepartment } from "../../../models/organisation/edit-application-department";
import { EditApplicationDepartmentCategory } from "../../../models/organisation/edit-application-department-category";
interface AdminDepartmentState {
  loading: boolean;
  selectedDepartment: EditApplicationDepartment | null;
  selectedCategory: EditApplicationDepartmentCategory | null;
  creatingCategory: boolean;
  error: string | null;
}

const initialState: AdminDepartmentState = {
  loading: false,
  selectedDepartment: null,
  selectedCategory: null,
  creatingCategory: false,
  error: null,
};

export const fetchDepartmentById = createAsyncThunk<
  ApplicationDepartment,
  number,
  { state: RootState }
>("departments/fetchById", async (departmentId, { rejectWithValue }) => {
  const departmentService = new DepartmentService();
  try {
    return await departmentService.getDepartmentById(departmentId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const updateDepartment = createAsyncThunk<
  ApplicationDepartment,
  ApplicationDepartment,
  { state: RootState }
>("departments/update", async (department, { rejectWithValue }) => {
  const departmentService = new DepartmentService();
  try {
    const updatedDepartment = await departmentService.updateDepartment(
      department
    );
    return updatedDepartment;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const deleteDepartmentCategory = createAsyncThunk<
  void,
  number,
  { state: RootState }
>(
  "departments/deleteCategory",
  async (departmentCategoryId, { rejectWithValue }) => {
    const departmentService = new DepartmentService();
    try {
      await departmentService.deleteDepartmentCategory(departmentCategoryId);
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);
export const deleteDepartment = createAsyncThunk<
  void,
  number,
  { state: RootState }
>("departments/deleteCategory", async (departmentId, { rejectWithValue }) => {
  const departmentService = new DepartmentService();
  try {
    await departmentService.deleteDepartment(departmentId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
export const createDepartment = createAsyncThunk<
  ApplicationDepartment,
  { name: string; productSelectionMode: string },
  { state: RootState }
>(
  "departments/create",
  async ({ name, productSelectionMode }, { rejectWithValue }) => {
    const departmentService = new DepartmentService();
    try {
      const newDepartment = await departmentService.createDepartment(
        name,
        productSelectionMode
      );
      return newDepartment;
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);

const adminDepartmentSlice = createSlice({
  name: "adminDepartment",
  initialState,
  reducers: {
    setSelectedCategory: (
      state,
      action: PayloadAction<EditApplicationDepartmentCategory>
    ) => {
      if (action.payload) {
        state.selectedCategory = {
          ...action.payload,
          newName: action.payload.name ?? "",
          newDescription: action.payload.description ?? "",
        };
        state.creatingCategory = false;
      } else {
        state.selectedCategory = null;
      }
    },
    setNewCategory(state) {
      const departmentId = state.selectedDepartment?.id ?? 0;
      state.selectedCategory = {
        id: 0,
        departmentId,
        name: "",
        description: "",
        active: false,
        canDelete: false,
      };
      state.creatingCategory = true;
    },
    toggleEdit: (
      state,
      action: PayloadAction<{
        field: "Name" | "ProductSelectionMode";
      }>
    ) => {
      const { field } = action.payload;

      if (state.selectedDepartment) {
        state.selectedDepartment[`edit${field}`] =
          !state.selectedDepartment[`edit${field}`];
      }
    },
    setNewValueForTarget: (
      state,
      action: PayloadAction<{
        target: "Department" | "Category";
        field: "Name" | "ProductSelectionMode" | "Description";
        value: number | string | boolean;
      }>
    ) => {
      const { target, field, value } = action.payload;
      const fieldMap = {
        Name: "newName",
        ProductSelectionMode: "newProductSelectionMode",
        Description: "newDescription",
      };
      const fieldToUpdate = fieldMap[field];
      if (target === "Department" && state.selectedDepartment) {
        (state.selectedDepartment as any)[fieldToUpdate] = value;
      } else if (target === "Category" && state.selectedCategory) {
        (state.selectedCategory as any)[fieldToUpdate] = value;
      }
    },
    applyNewValues: (
      state,
      action: PayloadAction<{ field: "Name" | "ProductSelectionMode" }>
    ) => {
      const { field } = action.payload;

      if (state.selectedDepartment) {
        const fieldMap = {
          Name: { newValue: "newName", edit: "editName", original: "name" },
          ProductSelectionMode: {
            newValue: "newProductSelectionMode",
            edit: "editProductSelectionMode",
            original: "productSelectionMode",
          },
        };
        const { newValue, original, edit } = fieldMap[field];
        if ((state.selectedDepartment as any)[newValue] !== undefined) {
          (state.selectedDepartment as any)[original] = (
            state.selectedDepartment as any
          )[newValue];
          (state.selectedDepartment as any)[edit] = false;
        }
      }
    },
    removeDepartmentCategory: (state, action: PayloadAction<number>) => {
      if (state.selectedDepartment) {
        state.selectedDepartment.departmentCategories =
          state.selectedDepartment.departmentCategories?.filter(
            (category) => category.id !== action.payload
          ) || [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDepartmentById.fulfilled,
        (state, action: PayloadAction<EditApplicationDepartment>) => {
          setDepartment(state, action.payload);
          state.loading = false;
        }
      )
      .addCase(
        fetchDepartmentById.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(
        updateDepartment.fulfilled,
        (state, action: PayloadAction<EditApplicationDepartment>) => {
          setDepartment(state, action.payload);
          state.selectedCategory = null;
          state.loading = false;
          state.error = null;
        }
      )

      .addCase(
        updateDepartment.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(
        createDepartment.fulfilled,
        (state, action: PayloadAction<ApplicationDepartment>) => {
          setDepartment(state, action.payload);
          state.loading = false;
          state.error = null;
        }
      );
  },
});
const setDepartment = (
  state: AdminDepartmentState,
  department: ApplicationDepartment
) => {
  state.selectedDepartment = {
    ...department,
    newName: department.name,
    newProductSelectionMode: department.productSelectionMode,
  };
};
export const {
  toggleEdit,
  applyNewValues,
  setSelectedCategory,
  setNewValueForTarget,
  setNewCategory,
  removeDepartmentCategory,
} = adminDepartmentSlice.actions;

export default adminDepartmentSlice.reducer;
