import { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../components/layout/admin-sidebar";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  fetchDepartments,
  removeDepartment,
} from "../../store/features/organisation/department-slice";
import TableView from "../../components/controls/table-view";
import { TextContainer, ContentWrapper } from "../../styles/shared/table";
import {
  fetchDepartmentById,
  updateDepartment,
  toggleEdit,
  applyNewValues,
  setNewValueForTarget,
  setNewCategory,
  deleteDepartment,
  removeDepartmentCategory,
  deleteDepartmentCategory,
} from "../../store/features/organisation/admin-department-slice";
import Button from "../../components/controls/button";
import { ICON_ID } from "../../components/icons/google-icon";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { ApplicationDepartmentCategory } from "../../models/organisation/application-department-category";
import { showSuccess } from "../../components/notification/toastr-actions";
import { requiredValidator } from "../../helpers/validators";
import EditableField from "../../components/controls/editable-field";
import { ColumnConfig } from "../../components/controls/table-view";
import { DrawerID } from "../../constants/drawer-constants";
import { openDrawer } from "../../store/features/drawer/drawer-slice";
import { EditApplicationDepartment } from "../../models/organisation/edit-application-department";
import {
  openConfirm,
  closeConfirm,
} from "../../store/features/notification/confirm-slice";
import useScreenSize from "../../hooks/useScreenSize";
import { setCurrentPage } from "../../store/features/authentication/user-slice";
interface DepartmentValidation {
  departmentName?: string | null;
}

const AdminDepartment = () => {
  const dispatch = useAppDispatch();
  const departments = useAppSelector((state) => state.department.departments)?.departments ?? [];
  const selectedDepartment = useAppSelector(
    (state) => state.adminDepartment.selectedDepartment
  );
  const [validation, setValidation] = useState<DepartmentValidation>({
    departmentName: null,
  });
  const { isMobile } = useScreenSize();

  useEffect(() => {
    dispatch(fetchDepartments()).then((action) => {
      if (
        fetchDepartments.fulfilled.match(action) &&
        action.payload.departments &&
        action.payload.departments.length > 0
        && selectedDepartment == null
      ) {
        const firstDepartment = action.payload.departments[0];
        if (firstDepartment) {
          handleDepartmentSelect(firstDepartment.id);
        }
      }
    });

    dispatch(setCurrentPage("Manage Departments"))
  }, [dispatch]);

  const handleDepartmentSelect = (departmentId: number) => {
    dispatch(fetchDepartmentById(departmentId));
  };

  const handleSave = async (
    property: keyof ApplicationDepartment,
    value: string,
    field: "Name" | "ProductSelectionMode"
  ) => {
    if (field === "Name") {
      const departmentNameError = requiredValidator(value);
      setValidation((prevState) => ({
        ...prevState,
        departmentName: departmentNameError,
      }));
      if (departmentNameError) {
        return;
      }
    }
    if (selectedDepartment) {
      const updatedDepartment: ApplicationDepartment = {
        ...selectedDepartment,
        [property]: value,
      };
      const response = await dispatch(updateDepartment(updatedDepartment));
      if (response) {
        dispatch(
          showSuccess(
            `Successfully updated the ${field === "Name" ? "department name" : "product selection mode"
            }.`
          )
        );
        dispatch(applyNewValues({ field }));
        dispatch(fetchDepartments());
      }
    }
  };

  const handleCloseButtonClick = (
    target: "Department",
    field: "Name" | "ProductSelectionMode",
    value: number | string
  ) => {
    dispatch(toggleEdit({ field }));
    dispatch(setNewValueForTarget({ target, field, value }));
    if (field === "Name") {
      setValidation({
        departmentName: null,
      });
    }
  };

  const onChange = <K extends keyof ApplicationDepartmentCategory>(
    value: ApplicationDepartmentCategory[K],
    target: "Department",
    property: "Name",
    validation?: (input: ApplicationDepartmentCategory[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;
    dispatch(
      setNewValueForTarget({
        target: target,
        field: property,
        value: value,
      })
    );
    setValidation((prevState) => ({
      ...prevState,
      [target.toLowerCase() + property]: errorMessage,
    }));
  };
  const handleCreateNewCategory = () => {
    dispatch(setNewCategory());
    dispatch(
      openDrawer({
        id: DrawerID.DepartmentCategory,
        anchor: "right",
        data: {
          headerText: "Add New Category",
        },
      })
    );
  };
  const handleCreateNewDepartment = () => {
    dispatch(
      openDrawer({
        id: DrawerID.Department,
        anchor: "right",
        data: {
          headerText: "Add Department",
        },
      })
    );
  };
  const handleDeleteCategoryClick = (row: any) => {
    const onConfirm = async () => {
      const response = await dispatch(deleteDepartmentCategory(row.id));
      if (response) {
        dispatch(removeDepartmentCategory(row.id));
        dispatch(closeConfirm());
        dispatch(showSuccess(`${row.name} deleted successfully.`));
      }
    };
    const onCancel = () => {
      dispatch(closeConfirm());
    };
    dispatch(
      openConfirm({
        title: "Remove " + row.name + "?",
        message: "Are you sure you want to delete this department category?",
        show: true,
        data: {
          onConfirm: onConfirm,
          onCancel: onCancel,
        },
      })
    );
  };
  const handleDeleteDepartmentClick = () => {
    if (!selectedDepartment?.id) {
      return;
    }
    const onConfirm = async () => {
      const response = await dispatch(deleteDepartment(selectedDepartment?.id));
      if (response) {
        const firstDepartment = departments[0];
        if (firstDepartment) {
          handleDepartmentSelect(firstDepartment.id);
        }
        dispatch(removeDepartment(selectedDepartment?.id));
        dispatch(closeConfirm());
        dispatch(
          showSuccess(`${selectedDepartment?.name} deleted successfully.`)
        );
      }
    };

    const onCancel = () => {
      dispatch(closeConfirm());
    };

    dispatch(
      openConfirm({
        title: "Remove " + selectedDepartment?.name + "?",
        message: "Are you sure you want to delete this department?",
        show: true,
        data: {
          onConfirm: onConfirm,
          onCancel: onCancel,
        },
      })
    );
  };
  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    departmentId: {
      hidden: true,
    },
    canDelete: {
      hidden: true,
    },
    active: {
      hidden: true,
    },
    name: {
      order: 3,
      name: "Department category",
      render: (value: number) => <TextContainer>{value}</TextContainer>,
    },
    description: {
      order: 4,
      render: (value: number) => <ContentWrapper>{value}</ContentWrapper>,
    },
  };
  const handleActivateDeactivateClick = async () => {
    if (!selectedDepartment) return;

    const updatedDepartment = {
      ...selectedDepartment,
      active: !selectedDepartment.active,
    };
    await dispatch(updateDepartment(updatedDepartment));
    dispatch(
      showSuccess(
        `${updatedDepartment.active ? "Enabled" : "Disabled"} ${updatedDepartment.name
        }`
      )
    );
  };
  const handleActivateDeactivateRow = async (
    row: ApplicationDepartmentCategory
  ) => {
    if (!selectedDepartment) return;

    const updatedDepartmentCategories =
      selectedDepartment.departmentCategories?.map((category) =>
        category.id === row.id
          ? {
            ...category,
            active: !category.active,
          }
          : category
      ) || [];

    const updatedDepartment: EditApplicationDepartment = {
      ...selectedDepartment,
      departmentCategories: updatedDepartmentCategories,
    };
    const response = await dispatch(updateDepartment(updatedDepartment));
    if (response) {
      dispatch(
        showSuccess(
          `${row.active ? "Disabled" : "Enabled"} ${row.name
          }`
        )
      );
    }
  };
  const renderActionButton = (row: any) => {
    return (
      <ButtonWrapper>
        <Button
          variant={isMobile ? "text" : "outlined"}
          id={row.id}
          label={isMobile ? "" : "Edit"}
          icon={isMobile ? ICON_ID.Edit : undefined}
          isPrimary={true}
          fullWidth={false}
          onClick={() =>
            dispatch(
              openDrawer({
                id: DrawerID.DepartmentCategory,
                anchor: "right",
                data: {
                  id: row.id,
                  headerText: "Edit Department Categories",
                },
              })
            )
          }
        />

        {row?.canDelete ? (
          <Button
            variant="outlined"
            id={row.id}
            label="Delete"
            isDanger={true}
            fullWidth={false}
            onClick={() => handleDeleteCategoryClick(row)}
          />
        ) : (
          <Button
            variant="text"
            id={row.id}
            label={row?.active ? "Disable" : "Enable"}
            isPrimary={!row?.active}
            isDanger={row?.active}
            onClick={() => handleActivateDeactivateRow(row)}
          />
        )}
      </ButtonWrapper>
    );
  };
  return (
    <Container>
      <NavigationSection>
        <AdminSidebar
          items={departments.map((department) => ({
            label: department.name,
            action: () => handleDepartmentSelect(department.id),
            isSelected: department.id == selectedDepartment?.id
          }))}
        >
          {!isMobile && (
            <CreateNewButtonContainer>
              <Button
                variant="text"
                icon={ICON_ID.Add}
                id="btnCreateNewDepartment"
                label="New"
                onClick={handleCreateNewDepartment}
              />
            </CreateNewButtonContainer>
          )}
        </AdminSidebar>
      </NavigationSection>
      <ContentSection>
        <ContentTitle>
          <FieldsGridContainer>
            <EditableField
              type="text"
              value={selectedDepartment?.newName || ""}
              isEditing={selectedDepartment?.editName || false}
              error={validation.departmentName}
              label="Department Name"
              displayValue={selectedDepartment?.name || "Select a department"}
              onChange={(value) =>
                onChange(value, "Department", "Name", (input) =>
                  requiredValidator(input)
                )
              }
              onSave={() =>
                handleSave("name", selectedDepartment?.newName || "", "Name")
              }
              onCancel={() =>
                handleCloseButtonClick(
                  "Department",
                  "Name",
                  selectedDepartment?.name || ""
                )
              }
              onEdit={() => dispatch(toggleEdit({ field: "Name" }))}
            />
            <ButtonWrapper>
              {selectedDepartment?.canDelete ? (
                <Button
                  variant="outlined"
                  id={`delete-department-${selectedDepartment?.id}`}
                  label={"Delete"}
                  isDanger={true}
                  fullWidth={false}
                  onClick={handleDeleteDepartmentClick}
                />
              ) : (
                <Button
                  variant="text"
                  id={`active/inactive-department-${selectedDepartment?.id}`}
                  label={selectedDepartment?.active ? "Disable" : "Enable"}
                  isPrimary={!selectedDepartment?.active}
                  isDanger={selectedDepartment?.active}
                  onClick={handleActivateDeactivateClick}
                />
              )}
            </ButtonWrapper>
          </FieldsGridContainer>
        </ContentTitle>
        <Body>
          <FieldsGridContainer>
            <EditableField
              type="dropdown"
              isEditing={selectedDepartment?.editProductSelectionMode || false}
              value={selectedDepartment?.newProductSelectionMode || ""}
              displayValue={`Product Selection Mode: ${selectedDepartment?.productSelectionMode}`}
              options={[
                { value: "Default", label: "Default" },
                { value: "Continous", label: "Continous" },
              ]}
              label="Product selection mode"
              onChange={(value) =>
                dispatch(
                  setNewValueForTarget({
                    target: "Department",
                    field: "ProductSelectionMode",
                    value: value,
                  })
                )
              }
              onSave={() =>
                handleSave(
                  "productSelectionMode",
                  selectedDepartment?.newProductSelectionMode || "",
                  "ProductSelectionMode"
                )
              }
              onCancel={() =>
                handleCloseButtonClick(
                  "Department",
                  "ProductSelectionMode",
                  selectedDepartment?.productSelectionMode || ""
                )
              }
              onEdit={() =>
                dispatch(toggleEdit({ field: "ProductSelectionMode" }))
              }
            />
          </FieldsGridContainer>
          <TableContainer>
            <ButtonContainer>
              <DepartmentCategoryTitle>
              </DepartmentCategoryTitle>
              <Button
                variant="contained"
                icon={ICON_ID.Add}
                id="btnCreateNewCategory"
                label={isMobile ? "Create New" : "New department category"}
                fullWidth={false}
                onClick={handleCreateNewCategory}
              />
            </ButtonContainer>

            <TableView
              id="departmentCategoryTable"
              emptyText="No department categories"
              totalCount={selectedDepartment?.departmentCategories?.length ?? 0}
              data={selectedDepartment?.departmentCategories ?? []}
              columnConfig={columnConfig}
              actionButton={renderActionButton}
              showHeaders={true}
            />
          </TableContainer>
        </Body>
      </ContentSection>
    </Container>
  );
};

const Body = styled.div`
  padding: 10px;
  height: 100%;
`;
const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;
  
  height: 97%;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const DepartmentCategoryTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`;
const TableContainer = styled.div`
  padding: 5px;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const ContentTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 2px solid ${(props) => props.theme.application.scheme.primary};
  padding: 5px 0px 5px 5px;
`;

const NavigationSection = styled.div`
  min-width: 15%;
`;

const ContentSection = styled.div`
  display: flex;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
  height: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    flex: 1;
    max-width: 100px;
  }

  @media (max-width: 500px) {
    gap: 0;
  }
`;

const FieldsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  align-items: center;
`;

const CreateNewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export default AdminDepartment;
