import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Button from "../../components/controls/button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { closeModal } from "../../store/features/modal/modal-slice";
import {
  fetchFiles,
  setSelectedFile,
  deleteFile,
  uploadFile,
  setLoadingfile,
  resetFiles,
} from "../../store/features/organisation/image-gallery-slice";
import TextInput from "../../components/controls/text-input";
import { useAppSelector } from "../../hooks/useAppSelector";
import Image from "../../components/controls/image";
import { ApplicationFile } from "../../models/organisation/application-file";
import {
  openConfirm,
  closeConfirm,
} from "../../store/features/notification/confirm-slice";
import { showSuccess } from "../../components/notification/toastr-actions";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import LoadingSpinner from "../../components/controls/loading-spinner";
import { LinearProgress, Alert, AlertTitle } from "@mui/material";
interface ImageGalleryModalProps {
  updateImage: (imagePath: string) => void;
}
interface FileProgress {
  progress: number;
  fileName: string;
}
export default function ImageGalleryModal({
  updateImage,
}: ImageGalleryModalProps) {
  const dispatch = useAppDispatch();
  const files = useAppSelector((state) => state.imageGallery).files;
  const [searchText, setSearchText] = useState("");
  const [fileProgress, setFileProgress] = useState<FileProgress>({
    progress: 0,
    fileName: "",
  });
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const selectedFile = useAppSelector(
    (state) => state.imageGallery
  ).selectedFile;
  const loadingRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchText.length > 2 || searchText == "") {
      dispatch(resetFiles());
      loadfiles();
    }
  }, [searchText]);

  const handleImageClick = (file: ApplicationFile) => {
    dispatch(setSelectedFile(file));
  };
  const setLoadingfiles = (loading: boolean) => {
    dispatch(setLoadingfile(loading));
  };

  const loadfiles = async () => {
    await dispatch(fetchFiles({ searchText: searchText }));
  };

  useInfiniteScroll({
    dataLength: files?.files?.length || 0,
    totalCount: files?.count || 0,
    fetchMoreData: loadfiles,
    setLoading: setLoadingfiles,
    loading: files?.loading || false,
    loadingRowRef,
  });

  const handleDeletefile = () => {
    const onConfirm = async () => {
      const response = await dispatch(deleteFile(selectedFile?.name || ""));
      if (response) {
        dispatch(closeConfirm());
        dispatch(showSuccess(`${selectedFile?.name} deleted successfully.`));
      }
    };
    const onCancel = () => {
      dispatch(closeConfirm());
    };
    dispatch(
      openConfirm({
        title: "Remove " + selectedFile?.name + "?",
        message: "Are you sure you want to delete this department category?",
        show: true,
        data: {
          onConfirm: onConfirm,
          onCancel: onCancel,
        },
      })
    );
  };

  const handleSelectfile = () => {
    updateImage(selectedFile?.fullPath || "");
    dispatch(closeModal());
  };

  const handleFilesUpload = async (files: FileList) => {
    const validImageTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/svg+xml",
    ];

    const validFiles = Array.from(files).filter((file) =>
      validImageTypes.includes(file.type)
    );
    const invalidFiles = Array.from(files).filter(
      (file) => !validImageTypes.includes(file.type)
    );

    setUploadErrors(
      invalidFiles.map((file) => `${file.name} is not a supported file type.`)
    );

    const initialProgress = { progress: 0, fileName: "" };
    setFileProgress(initialProgress);

    for (const file of validFiles) {
      setFileProgress({ progress: 0, fileName: file.name });
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          setFileProgress((prev) => {
            const newProgress = Math.min((prev.progress || 0) + 10, 100);
            if (newProgress === 100) {
              clearInterval(interval);
              resolve(true);
            }
            return { ...prev, progress: newProgress };
          });
        }, 100);
      });
      await dispatch(uploadFile(file));
    }
    setTimeout(() => {
      setFileProgress({ progress: 0, fileName: "" });
      setUploadErrors([]);
    }, 2000);
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleFilesUpload(e.dataTransfer.files);
    }
  };
  return (
    <Container>
      <Title>Image Gallery</Title>
      <TextInput
        id="txtSearchImages"
        label="Find images"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
      ></TextInput>
      <DragDrop onDragOver={handleDragOver} onDrop={handleDrop}>
        Drag and drop files to upload
        {Object.keys(fileProgress).length > 0 && fileProgress.progress > 0 && (
          <ProgressContainer>
            <p>{fileProgress.fileName}</p>
            <LinearProgress
              variant="determinate"
              value={fileProgress.progress}
            />
          </ProgressContainer>
        )}
        {uploadErrors.length > 0 && (
          <ErrorContainer>
            <Alert severity="warning">
              <AlertTitle>Validation Errors</AlertTitle>
              {uploadErrors.map((error, idx) => (
                <div key={idx}>{error}</div>
              ))}
            </Alert>
          </ErrorContainer>
        )}
        <GalleryContainer>
          {!files?.files ? (
            <LoadingSpinner />
          ) : files?.files && files.files.length > 0 ? (
            <Products>
              {files.files.map((file) => (
                <Product
                  key={`image${file.name}`}
                  ref={loadingRowRef}
                  onClick={() => handleImageClick(file)}
                >
                  <ProductImage isSelected={selectedFile?.name === file.name}>
                    <ImageWrapper>
                      <Image image={file.fullPath} />
                    </ImageWrapper>
                    <ProductName>{file.name}</ProductName>
                  </ProductImage>
                </Product>
              ))}
            </Products>
          ) : (
            <p>No images found</p>
          )}
        </GalleryContainer>
        <FileInfoContainer>
          <SelectedFileName>{selectedFile?.name}</SelectedFileName>
          <ButtonContainer>
            <Button
              id="btnDeleteFile"
              label="Delete File"
              variant="outlined"
              isDanger
              disabled={!selectedFile}
              onClick={() => handleDeletefile()}
            />
            <Button
              id="btnSelect"
              label="Select"
              variant="contained"
              disabled={!selectedFile}
              onClick={() => handleSelectfile()}
            />
          </ButtonContainer>
        </FileInfoContainer>
      </DragDrop>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 10px;
  border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
`;
const GalleryContainer = styled.div`
  margin-top: 10px;
  border: 1px solid ${(props) => props.theme.application.border};
  max-width: 500px;
  padding: 10px;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  overflow-y: auto;
  max-height: 50vh;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.application.product.background};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.application.scrollBar};
    border: 2px solid ${(props) => props.theme.application.scrollBar};
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    max-height: 40vh;
    max-width: 400px;
  }
`;

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const Product = styled.div`
  display: flex;
  justify-content: center;
`;

const ProductImage = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 80%;
  border: ${({ isSelected, theme }) =>
    isSelected
      ? `2px solid ${theme.application.scheme.primary}`
      : `1px solid ${theme.application.border}`};
  border-radius: 8px;
  padding: 10px;

  img {
    width: 100%;
    height: 45px;
    object-fit: cover;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

const ProductName = styled.span`
  text-align: center;
  font-size: 0.5rem;
  margin-top: 10px;
  color: #333;
  word-wrap: break-word;
`;
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  gap: 10px;
`;
const FileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const SelectedFileName = styled.span`
  font-size: 1rem;
  color: ${(props) => props.theme.application.text};
  flex: 1;
  text-align: start;
  word-wrap: break-word;
  width: 20px;
`;
const DragDrop = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  color: ${(props) => props.theme.application.text};
`;
const ErrorContainer = styled.div`
  margin-top: 20px;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
`;

const ProgressText = styled.div`
  margin-top: 8px;
  text-align: center;
`;
