import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationFileList } from "../../../models/organisation/application-file-list";
import { ApplicationError } from "../../../models/errors/application-error";
import { RootState } from "../../store";
import FileService from "../../../services/organisation/file-service";
import { ApplicationFile } from "../../../models/organisation/application-file";
export const fetchFiles = createAsyncThunk<
  ApplicationFileList | null,
  { searchText: string },
  { state: RootState }
>(
  "imageGallery/getAllFiles",
  async ({ searchText }, { getState, rejectWithValue }) => {
    const state = getState();
    const fileService = new FileService();

    try {
      var currentPosition: number =
        state.imageGallery?.files?.currentPosition ?? 0;
      var fetchNext: number = 15;
      var count: number = state.imageGallery.files?.count ?? -1;

      if (currentPosition != count) {
        return await fileService.getFiles(
          currentPosition,
          fetchNext,
          false,
          searchText
        );
      }
      return null;
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);
export const deleteFile = createAsyncThunk<void, string, { state: RootState }>(
  "imageGallery/deleteFile",
  async (name, { rejectWithValue }) => {
    const fileService = new FileService();
    try {
      return await fileService.deleteFile(name);
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);
export const uploadFile = createAsyncThunk<
  ApplicationFile,
  File,
  { state: RootState }
>("imageGallery/uploadFile", async (file, { rejectWithValue }) => {
  const fileService = new FileService();
  try {
    return await fileService.uploadFile(file);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
interface ImageGalleryState {
  files: ApplicationFileList | null;
  selectedFile: ApplicationFile | null;
}

const initialState: ImageGalleryState = {
  files: null,
  selectedFile: null,
};
const imageGallerySlice = createSlice({
  name: "imageGallery",
  initialState,
  reducers: {
    resetFiles: (state) => {
      state.files = initialState.files;
    },
    setSelectedFile: (state, action: PayloadAction<ApplicationFile>) => {
      state.selectedFile = action.payload;
    },
    resetSelectedFiles: (state) => {
      state.selectedFile = initialState.selectedFile;
    },
    setLoadingfile(state, action: PayloadAction<boolean>) {
      if (state.files) {
        state.files.loading = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchFiles.fulfilled,
        (state, action: PayloadAction<ApplicationFileList | null>) => {
          if (action.payload) {
            if (state.files?.files) {
              state.files.files = state.files.files.concat(
                action.payload!.files
              );
              state.files.currentPosition = action.payload!.currentPosition;
              state.files.count = action.payload!.count;
            } else {
              state.files = action.payload;
            }
          }
        }
      )
      .addCase(deleteFile.fulfilled, (state, action: PayloadAction<void>) => {
        if (state.files && state.selectedFile) {
          const index = state.files.files.findIndex(
            (file) => file.name === state.selectedFile?.name
          );

          if (index !== -1) {
            state.files.files.splice(index, 1);
            state.files.count -= 1;
            state.files.currentPosition -= 1;
            state.selectedFile = null;
          }
        }
      })
      .addCase(
        uploadFile.fulfilled,
        (state, action: PayloadAction<ApplicationFile | null>) => {
          if (action.payload) {
            if (state.files?.files) {
              state.files.files.unshift(action.payload);
              state.files.count += 1;
              state.files.currentPosition += 1;
            }
          }
        }
      );
  },
});

export const {
  resetFiles,
  setSelectedFile,
  resetSelectedFiles,
  setLoadingfile,
} = imageGallerySlice.actions;
export default imageGallerySlice.reducer;
