import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationFileList } from "../../models/organisation/application-file-list";
import { ApplicationFile } from "../../models/organisation/application-file";
import { components } from "../../external/schema/order-link-api-schema";
import client from "../../external/open-api";
import IFileService from "./file-service-interface";

export default class FileService implements IFileService {
  async getFiles(
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean,
    searchText: string
  ): Promise<ApplicationFileList> {
    const { data, error } = await client.GET("/v{version}/api/file", {
      params: {
        path: { version: "1" },
        query: {
          "Paging.CurrentPosition": currentPosition,
          "Paging.FetchNext": fetchNext,
          "Paging.FetchAll": fetchAll,
          SearchText: searchText,
        },
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch users");
    }

    return {
      files: this.mapToApplicationFiles(data.data.files ?? []),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }
  async deleteFile(name: string): Promise<void> {
    const { data, error } = await client.DELETE("/v{version}/api/file", {
      params: {
        path: { version: "1" },
      },
      body: {
        name: name,
      },
    });

    if (!data?.success || error) {
      throw new ApplicationError("Failed to delete the file");
    }
  }

  async uploadFile(file: File): Promise<ApplicationFile> {
    const { data, error } = await client.POST("/v{version}/api/file", {
      params: {
        path: { version: "1" },
      },

      body: {
        ContentType: file.type,
        FileName: file.name,
        Name: file.name,
        Length: file.size,
        File: file,
      },
      bodySerializer(body: any) {
        const fd = new FormData();
        for (const name in body) {
          fd.append(name, body[name]);
        }
        return fd;
      },
    });

    if (!data?.success || error || !data.data) {
      throw new ApplicationError("Failed to upload file");
    }
    return data.data as unknown as ApplicationFile;
  }

  private mapToApplicationFile(
    file: components["schemas"]["FileDTO"]
  ): ApplicationFile {
    return {
      name: file.name ?? "",
      size: file.size ?? 0,
      fullPath: file.fullPath ?? "",
    };
  }
  private mapToApplicationFiles(
    files: components["schemas"]["FileDTO"][]
  ): ApplicationFile[] {
    return files.map(this.mapToApplicationFile);
  }
}
