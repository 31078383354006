import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  fetchUsers,
  fetchUser,
  resetSelectedUser,
  setSearchText,
  resetUsers,
} from "../../store/features/organisation/admin-user-slice";
import { fetchBranches } from "../../store/features/organisation/branch-slice";
import styled from "styled-components";
import TableView, { ColumnConfig } from "../../components/controls/table-view";
import Button from "../../components/controls/button";
import {
  TableContainer,
  TextContainer,
  ContentWrapper,
  UnderlinedTextContainer,
} from "../../styles/shared/table";
import { ApplicationBranch } from "../../models/organisation/application-branch";
import { openDrawer } from "../../store/features/drawer/drawer-slice";
import { DrawerID } from "../../constants/drawer-constants";
import {
  GoogleIcon,
  ICON_SIZES,
  ICON_ID,
} from "../../components/icons/google-icon";
import useScreenSize from "../../hooks/useScreenSize";
import { sendResetLink } from "../../store/features/organisation/admin-user-slice";
import { showSuccess } from "../../components/notification/toastr-actions";
import { setCurrentPage } from "../../store/features/authentication/user-slice";
import { SmallSpacer } from "../../styles/shared/spacing";
export default function AdminUser() {
  const users = useAppSelector((state) => state.adminUser);
  const dispatch = useAppDispatch();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(setCurrentPage("Manage Users"))

    return () => {
      dispatch(setSearchText(""));
      dispatch(resetUsers());
    }
  }, [dispatch]);

  const fetchMoreData = async (search: boolean) => {
    await dispatch(fetchUsers({ search }));
  };
  const handleResetPassword = (row: any) => {
    dispatch(sendResetLink({ email: row.username }));
    dispatch(showSuccess("Password reset email sent"));
  };
  const columnConfig: { [key: string]: ColumnConfig } = {
    name: {
      order: 1,
    },
    username: {
      order: 2,
      colspan: isMobile ? 1 : 2,
      name: "Email Address",
      render: (value: string) => <ContentWrapper>{value}</ContentWrapper>,
    },
    branches: {
      hideOnMobile: true,
      order: 3,
      name: "Branch",
      render: (value: ApplicationBranch[]) => (
        <TextContainer>
          {value.map((x) => (
            <div>
              <GoogleIcon id={ICON_ID.Domain} ></GoogleIcon>{x.name}
              <SmallSpacer />
            </div>
          ))}
        </TextContainer>
      ),
    },
    role: {
      hideOnMobile: true,
      order: 4,
    },
    lastLogin: {
      hideOnMobile: true,
      order: 5,
      render: (value: Date) => (
        <TextContainer>
          {new Date(value).toLocaleDateString()}{" "}
          {new Date(value).toLocaleTimeString()}
        </TextContainer>
      ),
    },
    active: {
      hideOnMobile: true,
      order: 6,
      render: (value: boolean) => (
        <TextContainer>
          {value ? (
            <GoogleIcon
              tooltip="Active"
              id={ICON_ID.CheckCircle}
              size={ICON_SIZES.Large}
              isSuccess
            />
          ) : (
            <GoogleIcon
              tooltip="Disabled"
              id={ICON_ID.Close}
              size={ICON_SIZES.Large}
              isDanger
            />
          )}
        </TextContainer>
      ),
    },
    id: {
      order: 7,
      hidden: isMobile && true,
      name: "",
      render: (value: string, row: any) => (
        <UnderlinedTextContainer onClick={() => handleResetPassword(row)}>
          Send new password
        </UnderlinedTextContainer>
      ),
    },
  };

  const openNewUser = () => {
    dispatch(resetSelectedUser());
    dispatch(
      openDrawer({
        id: DrawerID.AdminUser,
        anchor: "right",
        data: {
          headerText: "Create New User",
        },
      })
    );
  };
  const EditUser = async (row: any) => {
    const response = await dispatch(fetchUser(row.id));
    if (response) {
      dispatch(
        openDrawer({
          id: DrawerID.AdminUser,
          anchor: "right",
          data: {
            headerText: "Edit User",
          },
        })
      );
    }
  };

  const renderActionButton = (row: any) => (
    <ButtonWrapper>
      {isMobile && (
        <Button
          variant="text"
          label=""
          id={row.Name}
          icon={ICON_ID.Password}
          isPrimary={true}
          fullWidth={false}
          onClick={() => handleResetPassword(row)}
        />
      )}
      <Button
        variant={isMobile ? "text" : "outlined"}
        id={row.Name}
        label={isMobile ? "" : "Edit"}
        icon={isMobile ? ICON_ID.Edit : undefined}
        isPrimary={true}
        fullWidth={false}
        onClick={() => EditUser(row)}
      />
    </ButtonWrapper>
  );

  return (
    <UserContainer>
      <ActionBar>
        <div>
          <Button
            id="createNewUser"
            onClick={openNewUser}
            label="Create new user"
            variant="contained"
            icon={ICON_ID.Add}
          ></Button>
        </div>
      </ActionBar>
      <TableContainer>
        <TableView
          id="No users"
          emptyText="No users"
          fetchMoreData={(search) => fetchMoreData(search ?? false)}
          totalCount={users.users?.count ?? 0}
          data={users?.users?.users ?? []}
          columnConfig={columnConfig}
          actionButton={renderActionButton}
          showHeaders={true}
          setSearchText={(searchText) => dispatch(setSearchText(searchText))}
          searchText={users.searchText}
        />
      </TableContainer>
    </UserContainer>
  );
}

const UserContainer = styled.div`
  height: 95%;
`;

const ActionBar = styled.div`
    display:flex;
    margin-bottom:20px;
    align-items: center;        

    div{
        margin-right:50px;
    }

    div.searchDiv{    
        flex:grow:1;
    }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    flex: 1;
    max-width: 100px;
  }

  @media (max-width: 500px) {
    gap: 0;
  }
`;
