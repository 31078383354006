import styled from "styled-components";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../components/icons/google-icon";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/lab/Alert";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setCurrentPage } from "../../store/features/authentication/user-slice";

export default function AdminHome() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(""))
  }, []);
  return (
    <Container>

      {/* <Selection onClick={() => navigate("/admin/approvals")}>
        <GoogleIcon
          id={ICON_ID.Approval}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Approvals
        <SelectionDescription>
          Approve order requests from store managers across all branches.
        </SelectionDescription>
      </Selection> */}
      <Selection onClick={() => navigate("/supplier")}>
        <GoogleIcon
          id={ICON_ID.Groups}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage suppliers
        <SelectionDescription>
          Your supplier management portal. Create new suppliers, update existing
          suppliers and update supplier products.
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/admin/department")}>
        <GoogleIcon
          id={ICON_ID.Category}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage Departments
        <SelectionDescription>
          Create new departments or edit existing ones, manage categories, and
          adjust department settings all in one place
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/admin/user")}>
        <GoogleIcon
          id={ICON_ID.ManageAccounts}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage Users
        <SelectionDescription>
          Create new users, request new passwords and update details of users.
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/admin/category")}>
        <GoogleIcon
          id={ICON_ID.Widgets}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage Product Categories
        <SelectionDescription>
          The area to modify product categories and have the ability to modify the products with the categories.
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/admin/products")}>
        <GoogleIcon
          id={ICON_ID.ViewList}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Master Product List
        <SelectionDescription>
          View the master product list across all branches. Create, update or re categorise products.
        </SelectionDescription>
      </Selection>
    </Container>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 2fr;
  }
`;

const Selection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  background: ${(props) => props.theme.application.background};
  border: 1px solid ${(props) => props.theme.application.border};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

`;


const SelectionDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.application.text};
  padding: 0 15px;
`;

