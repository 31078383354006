import styled from "styled-components";

export const TextContainer = styled.div`
  font-size: 1rem;
  font-weight: 400;

  span.bold {
    font-weight: 600;
  }
`;
export const SmallTextContainer = styled.div`
  font-size: 0.8rem;
  font-weight: 400;

  span.bold {
    font-weight: 600;
  }
`;
export const ContentWrapper = styled.div`
  word-break: break-word;
  @media (max-width: 768px) {
    padding-inline-end: 1.2rem;
  }
`;
export const TableContainer = styled.div`
  box-shadow: ${(props) => props.theme.application.boxShadow};
  height: 100%;  
`;
export const UnderlinedTextContainer = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`;
