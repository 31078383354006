import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';


import { RootState } from '../../store/store';
import { useAppSelector } from '../../hooks/useAppSelector';
import { removeNotification } from '../../store/features/notification/toastr-slice';
import Button from '../controls/button';
import { GoogleIcon, ICON_ID, ICON_SIZES } from '../icons/google-icon';



const Toastr: React.FC = () => {
    const dispatch = useDispatch();
    const notifications = useAppSelector((state) => state.toastr).notifications;

    const getGoogleIcon = (type: string) => {
        switch (type) {
            case "success":
                return ICON_ID.CheckCircle;
            case "error":
                return ICON_ID.Error
            default:
                return ICON_ID.Notification
        }
    }
    return (
        <ToastrContainer>
            {notifications.map((notification) => (
                <ToastrItem key={notification.id} colour={notification.type}>
                    <GoogleIcon isContrast={true} id={getGoogleIcon(notification.type)} size={ICON_SIZES.Medium}></GoogleIcon>
                    {notification.message}
                    <Button id="btnClose" label="" icon={ICON_ID.Close} variant="text" onClick={() => dispatch(removeNotification(notification.id))}></Button>
                </ToastrItem>
            ))
            }
        </ToastrContainer >
    );
};

export default Toastr;


const ToastrContainer = styled.div`
  position: fixed;
  top: 8%;
  right: 20px;
  z-index: 5;
`;

const ToastrItem = styled.div<{ colour: string }>`
    background-color:${({ theme, colour }) =>
        theme.application.scheme[getBackgroundColour(colour)] || theme.application.scheme.primary};
    color: white;
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 4px;
      box-shadow: ${props => props.theme.application.boxShadow}; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.9;
    button {
        span  {
        margin-right:0;
        color: ${({ theme }) => theme.application.scheme.contrast};
    }
}
`;

const getBackgroundColour = (colour: string) => {
    switch (colour) {
        case 'success':
            return 'success';
        case 'error':
            return 'danger';
        case 'warning':
            return 'warning';
        default:
            return 'primary';
    }
};