import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ApplicationSupplierDetail } from "../../models/supplier/application-supplier-detail";
import { ApplicationDay } from "../../models/supplier/application-day";
import { fetchSupplier, insertSupplier, updateSupplier } from "../../store/features/supplier/supplier-slice";
import { fetchDepartments } from "../../store/features/organisation/department-slice";
import { arrayRequired, emailValidator, phoneValidator, postcodeValidator, requiredValidator } from "../../helpers/validators";
import { SelectChangeEvent } from "@mui/material/Select/Select";
import { mapStringToEnum } from "../../helpers/enum-helper";
import { showSuccess } from "../../components/notification/toastr-actions";
import { closeModal } from "../../store/features/modal/modal-slice";
import { FeatureCard, FeatureCardColumn, FeatureCardRow, FeatureCardTitle } from "../../styles/shared/card";
import TextInput from "../../components/controls/text-input";
import Dropdown from "../../components/controls/dropdown";
import TimePicker from "../../components/controls/time-picker";
import dayjs from "dayjs";
import Checkbox from "../../components/controls/checkbox";
import Button from "../../components/controls/button";
import styled from "styled-components";
import { closeDrawer } from "../../store/features/drawer/drawer-slice";
import { fetchBranches } from "../../store/features/organisation/branch-slice";

interface SupplierModalProps {
    id: number;
}

interface Validation {
    name: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    city: string | null;
    postcode: string | null;
    phone: string | null;
    email: string | null;
    departments: string | null;
    deliveryDays: string | null;
    orderCutOffTime: string | null;
    branches: string | null;
}


export default function SupplierDrawer({ id }: SupplierModalProps) {
    const dispatch = useAppDispatch();
    const departments = useAppSelector((state) => state.department).departments?.departments ?? [];
    const branches = useAppSelector((state) => state.branch).branches;

    const [validation, setValidation] = useState<Validation>({
        name: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        city: null,
        postcode: null,
        phone: null,
        email: null,
        departments: null,
        deliveryDays: null,
        orderCutOffTime: null,
        branches: null,
    });

    const [supplier, setSupplier] = useState<ApplicationSupplierDetail>({
        id: 0,
        name: "",
        noOfProducts: 0,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        postcode: "",
        phone: "",
        email: "",
        updated: new Date(),
        apiIntegration: false,
        emailIntegration: false,
        whatsappIntegration: false,
        departmentIds: [],
        deliveryDays: [
            ApplicationDay.Monday,
            ApplicationDay.Tuesday,
            ApplicationDay.Wednesday,
            ApplicationDay.Thursday,
            ApplicationDay.Friday,
            ApplicationDay.Saturday,
        ],
        orderCutOffTime: "",
        branches: [],
    });

    const [departmentOptions, setDepartmentOptions] = useState<
        { value: string; label: string }[]
    >([{ value: "", label: "" }]);

    const [branchOptions, setBranchOptions] = useState<
        { value: string; label: string }[]
    >([{ value: "", label: "" }]);

    const [deliveryDayOptions, _] = useState<{ value: string; label: string }[]>([
        { value: ApplicationDay.Monday, label: ApplicationDay.Monday },
        { value: ApplicationDay.Tuesday, label: ApplicationDay.Tuesday },
        { value: ApplicationDay.Wednesday, label: ApplicationDay.Wednesday },
        { value: ApplicationDay.Thursday, label: ApplicationDay.Thursday },
        { value: ApplicationDay.Friday, label: ApplicationDay.Friday },
        { value: ApplicationDay.Saturday, label: ApplicationDay.Saturday },
        { value: ApplicationDay.Sunday, label: ApplicationDay.Sunday },
    ]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSupplier(id)).then((response) => {
                const supplier = response.payload as ApplicationSupplierDetail;
                setSupplier(supplier);
            });
        }
    }, [id]);

    useEffect(() => {
        if (!branches) {
            dispatch(fetchBranches());
        }
    }, [branches]);

    useEffect(() => {
        if (departments && departments.length > 0) {
            setDepartmentOptions(
                departments.map((department) => ({
                    value: department.id.toString(),
                    label: department.name,
                }))
            );
        } else {
            dispatch(fetchDepartments());
        }
    }, [departments]);

    useEffect(() => {
        if (branches) {
            setBranchOptions(
                branches.map((branch) => ({
                    value: branch.id.toString(),
                    label: branch.name,
                }))
            );
        }
    }, [branches]);

    const onChange = <K extends keyof ApplicationSupplierDetail>(
        value: ApplicationSupplierDetail[K],
        property: K,
        validation?: (input: ApplicationSupplierDetail[K]) => string | null
    ) => {
        const errorMessage = validation ? validation(value) : null;

        setSupplier((prevState) => ({
            ...prevState,
            [property]: value,
        }));

        setValidation((prevState) => ({
            ...prevState,
            [property]: errorMessage,
        }));
    };

    const handleDepartmentSelection = (event: SelectChangeEvent<unknown>) => {
        const {
            target: { value },
        } = event;

        const departments =
            typeof value === "string" ? value.split(",") : (value as string[]);
        const departmentIds = departments.map((x) => parseInt(x));
        setSupplier((prevState) => ({
            ...prevState,
            departmentIds: departmentIds,
        }));

        setValidation((prevState) => ({
            ...prevState,
            departments: arrayRequired(departmentIds),
        }));
    };

    const handleBranchSelection = (event: SelectChangeEvent<unknown>) => {
        const {
            target: { value },
        } = event;

        const branchInput =
            typeof value === "string" ? value.split(",") : (value as string[]);
        const foundBranches = branches?.filter(
            (x) => branchInput.indexOf(x.id.toString()) != -1
        );

        setSupplier((prevState) => ({
            ...prevState,
            branches: foundBranches ?? [],
        }));

        setValidation((prevState) => ({
            ...prevState,
            branches: arrayRequired(foundBranches ?? []),
        }));
    };

    const handleDeliveryDays = (event: SelectChangeEvent<unknown>) => {
        const {
            target: { value },
        } = event;

        const days =
            typeof value === "string" ? value.split(",") : (value as string[]);
        const deliveryDays = days.map((x) => mapStringToEnum(ApplicationDay, x));

        setSupplier((prevState) => ({
            ...prevState,
            deliveryDays: deliveryDays,
        }));

        setValidation((prevState) => ({
            ...prevState,
            deliveryDays: arrayRequired(deliveryDays),
        }));
    };

    const onUpdate = async () => {
        if (!validateAndCheckErrors()) {
            return;
        }

        let notificationMessage = `${supplier.name} `;
        if (supplier.id == 0) {
            await dispatch(insertSupplier(supplier));
            notificationMessage += "created";
        } else {
            await dispatch(updateSupplier(supplier));
            notificationMessage += "updated";
        }

        dispatch(showSuccess(notificationMessage));
        dispatch(closeDrawer());
    };

    const validateAndCheckErrors = () => {
        const validation = {
            name: requiredValidator(supplier.name),
            addressLine1: requiredValidator(supplier.addressLine1),
            addressLine2: null,
            addressLine3: null,
            city: requiredValidator(supplier.city),
            postcode: postcodeValidator(supplier.postcode),
            phone: phoneValidator(supplier.phone),
            email: emailValidator(supplier.email),
            departments: arrayRequired(supplier.departmentIds),
            deliveryDays: arrayRequired(supplier.deliveryDays),
            orderCutOffTime: requiredValidator(supplier.orderCutOffTime),
            branches: arrayRequired(supplier.branches),
        };

        setValidation(validation);

        const hasErrors = Object.values(validation).some((error) => error !== null);

        if (hasErrors) {
            return false; // Or handle the errors as needed
        } else {
            return true; // Proceed with form submission or further processing
        }
    };

    const getDepartmentSelectionName = (selected: string[]) => {
        if (selected.length > 1) {
            return "Multiple selected";
        }

        var selectedDepartments = departments
            .filter((x) => selected.indexOf(x.id.toString()) != -1)
            .map((department) => department.name)
            .join(", ");
        return selectedDepartments;
    };

    const getBranchSelectionName = (selected: string[]) => {
        if (selected.length > 1) {
            return "Multiple selected";
        }

        var selectedBranches = branches
            ?.filter((x) => selected.indexOf(x.id.toString()) != -1)
            .map((branch) => branch.name)
            .join(", ");
        return selectedBranches;
    };

    const getDeliveryDaysSelection = (selected: string[]) => {
        if (selected.length > 2) {
            return "Multiple selected";
        }

        var selectedOptions = deliveryDayOptions
            .filter((x) => selected.indexOf(x.value.toString()) != -1)
            .map((option) => option.value)
            .join(", ");

        return selectedOptions;
    };

    return (
        <Container>
            <FeatureCard>
                <FeatureCardTitle>Basic information</FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtSupplierName"
                            value={supplier.name}
                            label="Name"
                            onChange={(event) =>
                                onChange(event.target.value, "name", (input) =>
                                    requiredValidator(input)
                                )
                            }
                            error={validation.name}
                        />
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <Dropdown
                            id="cboDepartment"
                            variant="outlined"
                            label="Select departments"
                            renderValue={(selected) =>
                                getDepartmentSelectionName(selected as string[])
                            }
                            options={departmentOptions}
                            onChange={handleDepartmentSelection}
                            value={supplier.departmentIds?.map((x) => x.toString()) ?? []}
                            multiple
                            errorMessage={validation.departments}
                            autoWidth={false}
                            native={false}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <Dropdown
                            id="cboDeliveryDays"
                            variant="outlined"
                            label="Delivery days"
                            renderValue={(selected) =>
                                getDeliveryDaysSelection(selected as string[])
                            }
                            options={deliveryDayOptions}
                            onChange={handleDeliveryDays}
                            value={supplier.deliveryDays?.map((x) => x.toString()) ?? []}
                            multiple
                            errorMessage={validation.deliveryDays}
                            autoWidth={false}
                            native={false}
                        />
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <TimePicker
                            id="orderCutOffTime"
                            label="Order cut off time"
                            value={dayjs(supplier.orderCutOffTime, "HH:mm")}
                            onChange={(value) => {
                                if (value && dayjs(value).isValid()) {
                                    onChange(
                                        value.utc(true).format("HH:mm:ss"),
                                        "orderCutOffTime",
                                        (input) => requiredValidator(input)
                                    );
                                } else {
                                    onChange("", "orderCutOffTime", (input) =>
                                        requiredValidator(input)
                                    );
                                }
                            }}
                            error={validation.orderCutOffTime}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <Dropdown
                            id="cboBranch"
                            variant="outlined"
                            label="Select branch"
                            renderValue={(selected) =>
                                getBranchSelectionName(selected as string[])
                            }
                            options={branchOptions}
                            onChange={handleBranchSelection}
                            value={supplier.branches?.map((x) => x.id.toString()) ?? []}
                            multiple
                            errorMessage={validation.branches}
                            autoWidth={false}
                            native={false}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>
            <FeatureCard>
                <FeatureCardTitle>Location</FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtAddressLine1"
                            value={supplier.addressLine1}
                            label="Address line 1"
                            onChange={(event) =>
                                onChange(event.target.value, "addressLine1", (input) =>
                                    requiredValidator(input)
                                )
                            }
                            error={validation.addressLine1}
                        />
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtAddressLine2"
                            value={supplier.addressLine2}
                            label="Address line 2"
                            onChange={(event) => onChange(event.target.value, "addressLine2")}
                            error={validation.addressLine2}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtAddressLine3"
                            value={supplier.addressLine3}
                            label="Address line 3"
                            onChange={(event) => onChange(event.target.value, "addressLine3")}
                            error={validation.addressLine3}
                        />
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtCity"
                            value={supplier.city}
                            label="City"
                            onChange={(event) =>
                                onChange(event.target.value, "city", (input) =>
                                    requiredValidator(input)
                                )
                            }
                            error={validation.city}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtPostcode"
                            value={supplier.postcode}
                            label="Postcode"
                            onChange={(event) =>
                                onChange(event.target.value, "postcode", (input) =>
                                    postcodeValidator(input)
                                )
                            }
                            error={validation.postcode}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>

            <FeatureCard>
                <FeatureCardTitle>Contact information</FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtPhone"
                            value={supplier.phone}
                            label="Phone"
                            onChange={(event) =>
                                onChange(event.target.value, "phone", (input) =>
                                    phoneValidator(input)
                                )
                            }
                            error={validation.phone}
                        />
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtEmail"
                            value={supplier.email}
                            label="Email"
                            onChange={(event) =>
                                onChange(event.target.value, "email", (input) =>
                                    emailValidator(input)
                                )
                            }
                            error={validation.email}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>

            <FeatureCard>
                <FeatureCardTitle>Automation</FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <Checkbox
                            label="Email"
                            checked={supplier.emailIntegration}
                            onChange={(event) =>
                                onChange(event.target.checked, "emailIntegration")
                            }
                        ></Checkbox>
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>
            <ButtonContainer>
                <Button
                    id="btnCreate"
                    label={supplier.id == 0 ? "Create" : "Update"}
                    variant="contained"
                    onClick={onUpdate}
                ></Button>
            </ButtonContainer>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding:10px;
  `;

const Title = styled.div`
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};
  `;

const ButtonContainer = styled.div`
  display:flex;
  margin-top:10px;
      justify-content: flex-end;
  
      button:first-of-type{
          margin-right:10px;
      }
  }
`