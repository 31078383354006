import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import TableView, { ColumnConfig } from "../../components/controls/table-view";
import {
  ProductContainer,
  ProductImage,
  ProductNameContainer,
  ProductTitle,
} from "../../styles/shared/product";
import styled from "styled-components";
import {
  fetchOrderLines,
  resetOrderLines,
  setNewValue,
  setSearch,
} from "../../store/features/ordering/order-slice";
import Image from "../../components/controls/image";
import { ApplicationApprovalStatus } from "../../models/order/application-approval-status";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../components/icons/google-icon";
import {
  SmallTextContainer,
  TableContainer,
  TextContainer,
} from "../../styles/shared/table";
import TextInput from "../../components/controls/text-input";

export default function OrderView() {
  const order = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();
  const deliveryMode = order.deliveryMode;
  const fetchMoreData = async (search: boolean) => {
    if (order.order?.id) {
      await dispatch(fetchOrderLines({ orderId: order.order.id, search }));
    }
  };

  useEffect(() => {
    fetchMoreData(false);

    return () => {
      dispatch(setSearch(""));
      dispatch(resetOrderLines())
    }
  }, [order.order?.id]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (deliveryMode) {
        event.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [order, deliveryMode]);

  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    productId: {
      hidden: true,
    },
    productDescription: {
      hidden: true,
    },
    productSize: {
      hidden: true,
    },
    productImage: {
      hidden: true,
    },
    plu: {
      hidden: true,
    },
    sizeUnit: {
      hidden: true
    },
    size: {
      hidden: true
    },
    status: {
      hidden: true,
    },
    productName: {
      order: 1,
      name: "Product",
      colspan: 3,
      render: (value: number, row: any) => (
        <ProductContainer>
          {row.approvalStatus == ApplicationApprovalStatus.AwaitingApproval && (
            <GoogleIcon
              tooltip="Awaiting approval"
              id={ICON_ID.Error}
              isWarning
              size={ICON_SIZES.Large}
            ></GoogleIcon>
          )}

          {row.approvalStatus == ApplicationApprovalStatus.Approved && (
            <GoogleIcon
              tooltip="Approved"
              id={ICON_ID.CheckCircle}
              size={ICON_SIZES.Large}
              isSuccess
            ></GoogleIcon>
          )}

          {row.approvalStatus == ApplicationApprovalStatus.Rejected && (
            <GoogleIcon
              tooltip="Rejected"
              id={ICON_ID.Close}
              size={ICON_SIZES.Large}
              isDanger
            ></GoogleIcon>
          )}

          <ProductImage>
            <Image image={row.productImage}></Image>
          </ProductImage>
          <ProductNameContainer>
            <ProductTitle>{value}</ProductTitle>
            <div>
              {row.size} {row.sizeUnit} - {row.productDescription}
            </div>
            <SmallTextContainer>Plu: {row.plu}</SmallTextContainer>
          </ProductNameContainer>
        </ProductContainer>
      ),
    },
    quantity: {
      order: 3,
      render: (value: number, row: any) => (
        <TextContainer>Qty: {value}</TextContainer>
      ),
    },
    approvalStatus: {
      hidden: true,
    },
  };

  if (deliveryMode) {
    Object.assign(columnConfig, {
      quantityReceived: {
        order: 4,
        name: "Delivered",
        render: (value: string, row: any) => (
          <TextInput
            label="Quantity Received"
            type="number"
            id="Quantity-Received-input"
            value={value as string}
            onChange={(event) =>
              dispatch(
                setNewValue({
                  productId: row.productId,
                  field: "quantityReceived",
                  value: event.target.value,
                })
              )
            }
            sx={{ marginTop: "0px", marginBottom: "0px" }}
          />
        ),
      },
      deliveryNotes: {
        order: 5,
        name: "Delivery Notes",
        render: (value: string, row: any) => (
          <TextInput
            label="Delivery Note"
            type="text"
            id="Delivery-Note-input"
            value={value as string}
            sx={{ marginTop: "0px", marginBottom: "0px" }}
            onChange={(event) =>
              dispatch(
                setNewValue({
                  productId: row.productId,
                  field: "deliveryNotes",
                  value: event.target.value,
                })
              )
            }
          />
        ),
      },
      notes: { hidden: true },
    });
  } else {
    Object.assign(columnConfig, {
      notes: {
        order: 4,
        render: (value: string) => (
          <SmallTextContainer>{value}</SmallTextContainer>
        ),
      },
      quantityReceived: { hidden: true },
      deliveryNotes: { hidden: true },
    });
  }
  return (
    <OrderContainer>
      <TableContainer>
        <TableView
          id="ordersTable"
          emptyText="Hmmmm, you seem to have no items in your order, this must be an error"
          fetchMoreData={(search) => fetchMoreData(search ?? false)}
          totalCount={order.orderLines?.count ?? 0}
          data={order.orderLines?.orderLines ?? []}
          columnConfig={columnConfig}
          preLoadedData={true}
          showHeaders={true}
          searchText={order.searchText}
          setSearchText={(searchText) => dispatch(setSearch(searchText))}
        />
      </TableContainer>
    </OrderContainer>
  );
}
const OrderContainer = styled.div`
  width: 90%;
  margin: auto;
  height: 100%;
`;
